$(document).ready(function () {
    $('.arrow-down').on('click', function () {
        $('html, body').animate({
            scrollTop: $(".section-cta").offset().top
        }, 1000);

    });
});

$(window).on('scroll', function () {

});

$(window).on('resize', function () {

});